<template>
  <page-header-wrapper @back="back">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('用户.手机号')" prop="mobile">
                <a-input v-model="queryParam.mobile" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('用户.手机号')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('用户.邮箱')" prop="email">
                <a-input v-model="queryParam.email" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('用户.邮箱')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('用户.昵称')" prop="email">
                <a-input v-model="queryParam.nickName" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('用户.昵称')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('销售订单.下单开始时间')" prop="createBeginTime">
                <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"
                               :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                               valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('销售订单.下单结束时间')" prop="createEndTime">
                <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"
                               :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"
                               valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8 || 24">
                <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                  <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                  <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
                </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
        <div class="table-operations">
          <a-button type="primary" :disabled="multiple"  @click="handleSend()">
            <a-icon type="bell" />{{$t('用户.发送消息')}}
          </a-button>
        </div>
      </div>
      <notice-user ref="noticeUser"/>

      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="userId"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        @change="handleSortChange"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
            {{ parseTime(record.createTime) }}
          </span>
        <template slot="avatar" slot-scope="text, record">
          <div>
            <img v-if="record.avatar" :src="record.avatar"  style="width:60px;height:62px;" @click="handleRecordPreview(record.avatar)" alt="none"/>
          </div>
        </template>
        <span slot="sex" slot-scope="text, record">
           <custom-dict-tag :options="customDict.SexEnum" :value="record.sex"/>
        </span>
        <span slot="nation" slot-scope="text, record">
           <custom-dict-tag :options="customDict.UserNationEnum" :value="record.nation"/>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {getActivityRank} from '@/api/activity/activity'
import {mapGetters} from 'vuex'
import moment from 'moment';
import CustomDictTag from "@/components/DictCustomTag";
import {translation} from "@/api/tool/common";
import {tableMixin} from '@/store/table-mixin'
import NoticeUser from '@/views/activity/activity/modules/NoticeUser'
import events from "@/components/MultiTab/events";
import bus from "@/utils/bus";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
    NoticeUser
  },
  mixins: [tableMixin],
  data () {
    return {
      id: null,
      open:false,
      formTitle: null,
      list: [],
      pageSizeOptions: ['10', '30', '50', '100'],
      filteredInfo: {},
      sortedInfo: {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        activityId: this.id,
        mobile: undefined,
        nickName: undefined,
        email: undefined,
        createBeginTime:undefined,
        createEndTime:undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: this.$t('用户.昵称'),
          dataIndex: 'nickName',
          align: 'center'
        },
        {
          title: this.$t('用户.头像'),
          dataIndex: 'avatar',
          scopedSlots: {customRender: 'avatar'},
          align: 'center'
        },
        {
          title: this.$t('用户.邮箱'),
          dataIndex: 'email',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('用户.性别'),
          dataIndex: 'sex',
          scopedSlots: {customRender: 'sex'},
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('销售订单.商品数量'),
          dataIndex: 'goodsCount',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('销售订单.订单数量'),
          dataIndex: 'orderCount',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: this.$t('收货地址.收货区域'),
          scopedSlots: {customRender: 'nation'},
          dataIndex: 'nation',
          align: 'center'
        },
        {
          title: this.$t('销售订单.收货人姓名'),
          dataIndex: 'realName',
          align: 'center'
        },
        {
          title: this.$t('收货地址.收货人手机号'),
          dataIndex: 'addressMobile',
          align: 'center'
        },
        {
          title: this.$t('收货地址.应援地址'),
          dataIndex: 'address',
          align: 'center'
        },
      ]
    };
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
    let id = this.$route.query.id
    if (id) {
      this.handleRank(id)
    }
  },
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询活动记录列表 */
    getList () {
      this.loading = true
      this.queryParam.activityId=this.id
      getActivityRank(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        activityId: this.id,
        mobile: undefined,
        nickName: undefined,
        email: undefined,
        createBeginTime:undefined,
        createEndTime:undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1

    },
    limitMaxNum(sort) {
      let val = sort;
      if(val==null||val==undefined||val==''|| val<0){
        val=0
      }
      if (val > 999999) {
        val = 999999
      }
      this.form.limitNum = val
    },
    handleGoodsDelete(key) {
      const dataSource = [...this.form.goodsList];
      this.form.goodsList = dataSource.filter(item => item.id !== key);
    },
    /*text 要翻译的字段*/
    getTranslation(record) {
      if (!this.form.activityName && !this.form.posterTxt) {
        return;
      }
      let data = {"text": this.form.activityName, "locale": record.locale};
      translation(data).then(response => {
        record.activityName = response.data
      })
      let data2 = {"text": this.form.posterTxt, "locale": record.locale};
      translation(data2).then(response => {
        record.posterTxt = response.data
      })
    },
    limitSort(sort) {
      let val = sort;
      if(val==null||val==undefined||val==''|| val<0){
        val=0
      }
      if (val > 999999) {
        val = 999999
      }
      this.form.sort = val
    },


    getCategoryNameById(id) {
      if (!id) {
        return "";
      }
      let cateInfo = this.categoryList.find(e => e.id === id);
      if (cateInfo) {
        return cateInfo.categoryName
      } else {
        return "";
      }
    },

    getCateList() {
      listCategory().then(response => {
        this.categoryList = response.data;
        const cate = { id: "0", categoryName: this.$t('通用.文本.根节点'), children: [] }
        cate.children = this.handleTree(response.data, 'id','pid')
        this.cateOptions.push(cate)
      })
    },
    getBrandList() {
      allBrand().then(response => {
        this.brandList = response.data
      })
    },
    getBrandNameById(id) {
      if (!id) {
        return "";
      }
      let brandInfo = this.brandList.find(e => e.id === id);
      if (brandInfo) {
        return brandInfo.name
      } else {
        return "";
      }
    },
    handleRank(id) {
      this.id = id;
      this.open = true;
      this.formTitle = this.$t('活动记录.排行')
      this.getList()
    },
    handleSend () {
      this.$refs.noticeUser.handleAdd(this.selectedRows,this.id);
    },
    back() {
      let pages = this.$store.pages;
      pages.indexOf(this.$route)
      events.$emit('close', pages.indexOf(this.$route))
      // 去更新列表页的数据
      this.$router.push({path: '/activity/activity', replace: true, query:{}  })
    },
  }
}
</script>
